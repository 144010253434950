import { computed, ref } from 'vue'
import { http } from '@/helpers/http'
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { useModalStore } from './modal'
import { useUserStore } from './user'
import { useLoaderStore } from './loader'
import type { TLandingPage, TLandingPageMeta, TLandingPageSummary } from '@/types/landing-pages'
import type { TProject } from '@/types/projects'

export const useLandingPagesStore = defineStore('landing-pages', () => {
  const modals = useModalStore()
  const user = useUserStore()
  const router = useRouter()
  const store = useStorage<TLandingPageMeta | null>('landingPageTempData', {} as TLandingPageMeta)
  const loader = useLoaderStore()

  const landingPages = ref<TLandingPage[]>([])
  const landingPage = ref<TLandingPage>()
  const projectId = ref<number | null>(null)
  const draftsIdsWithoutThumbnails = ref<number[]>([])
  const generateRunning = ref<boolean>(false)
  const generateTimeout = ref<NodeJS.Timeout | null>(null)
  const languages = [
    { name: 'English', label: '🇬🇧 English', code: 'en' },
    { name: 'Dutch', label: '🇳🇱 Dutch', code: 'nl' },
    { name: 'French', label: '🇫🇷 French', code: 'fr' },
    { name: 'German', label: '🇩🇪 German', code: 'de' },
    { name: 'Hungarian', label: '🇭🇺 Hungarian', code: 'hu' },
    { name: 'Italian', label: '🇮🇹 Italian', code: 'it' },
    { name: 'Polish', label: '🇵🇱 Polish', code: 'pl' },
    { name: 'Portuguese', label: '🇵🇹 Portuguese', code: 'pt' },
    { name: 'Romanian', label: '🇷🇴 Romanian', code: 'ro' },
    { name: 'Spanish', label: '🇪🇸 Spanish', code: 'es' },
  ]
  const sortOptions = [
    { label: 'Newest', name: 'newest', icon: 'fa-clock' },
    { label: 'Oldest', name: 'oldest', icon: 'fa-clock' },
    { label: 'A - Z', name: 'az', icon: 'fa-arrow-down' },
    { label: 'Z - A', name: 'za', icon: 'fa-arrow-up' },
  ]
  const sort = ref(sortOptions[0])
  const query = ref('')

  const filteredAndSorted = computed(() => {
    return landingPages.value
      .filter((item) => {
        return item.title.toLowerCase().includes(query.value.toLowerCase())
      })
      .sort((a: TLandingPage, b: TLandingPage) => {
        if (sort.value.name === 'newest') {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        } else if (sort.value.name === 'oldest') {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        } else if (sort.value.name === 'az') {
          return a.title.localeCompare(b.title)
        } else {
          return b.title.localeCompare(a.title)
        }
      })
  })

  async function fetch() {
    const res = await http.get<{
      results: TLandingPage[]
    }>(`/landing-pages`)

    landingPages.value = res?.results ?? []

    draftsIdsWithoutThumbnails.value = landingPages.value.filter((item) => !item.thumbnail).map((item) => item.id)

    if (draftsIdsWithoutThumbnails.value.length > 0) {
      generateRunning.value = true
      checkThumbnail()
    }
  }

  async function fetchById(id: number) {
    try {
      const res = await http.get<TLandingPage>(`/landing-pages/${id}`)
      landingPage.value = res ?? null
      return landingPage
    } catch (e) {
      router.push('/landing-pages')
      return
    }
  }

  async function checkThumbnail() {
    const id = draftsIdsWithoutThumbnails.value.length > 0 ? draftsIdsWithoutThumbnails.value[0] : null
    if (!id) {
      generateRunning.value = false
      return
    }

    draftsIdsWithoutThumbnails.value.shift()

    if (generateRunning.value) {
      await generateThumbnail(id)
      generateTimeout.value = setTimeout(() => {
        checkThumbnail()
      }, 1000)
    } else {
      generateRunning.value = false
    }
  }

  async function generateThumbnail(id: number) {
    const result = await http.get<{ thumbnail: string }>(`/landing-pages/generate_thumbnail/${id}`)
    if (result) {
      const landingPage = landingPages.value.find((page) => page.id === id)
      if (landingPage) {
        landingPage.thumbnail = result.thumbnail
      }
    }
  }

  async function generate3DCover(designId: number, projectId: number): Promise<string> {
    const data = {
      designId: designId,
      projectId: projectId,
      imageId: null,
      pdfUrl: null,
    }
    try {
      const result = await http.post(`/create_3dcover`, data, true)
      return result ?? ''
    } catch (_) {
      return ''
    }
  }

  async function getProjectMeta(id: number) {
    const res = await http.get<TLandingPageMeta>(`/landing-pages/project/${id}`)

    return res ?? null
  }

  async function rename(id: number, title: string): Promise<boolean> {
    try {
      const res = await http.post<{ result: boolean }>(`/landing-pages/rename`, { id, title })
      return res.result || false
    } catch {
      return false
    }
  }

  async function remove(id: number): Promise<boolean> {
    try {
      const res = await http.delete<{ result: boolean }>(`/landing-pages/${id}`)
      res.result && fetch()
      return res.result || false
    } catch {
      return false
    }
  }

  async function removeMany(ids: number[]): Promise<boolean> {
    try {
      const res = await http.post<{ result: boolean }>(`/landing-pages/delete-many`, { ids })
      res.result && fetch()
      return res.result || false
    } catch {
      return false
    }
  }

  async function getSlug(title: string): Promise<string> {
    try {
      const res = await http.post<{ result: { content: string } }>(`/ai/slug-from-title`, { title })
      return res.result.content || ''
    } catch {
      return ''
    }
  }

  async function getSummary(id: number): Promise<TLandingPageSummary> {
    try {
      const res = await http.get<TLandingPageSummary>(`/projects/${id}/summary`)
      if (!res.result) throw 'Generating error'
      return res
    } catch {
      return {
        result: false,
        summary: '',
        short_summary: '',
        background: '',
        language: 'en',
      }
    }
  }

  async function create(project: TProject): Promise<boolean> {
    try {
      loader.show('Preparing...')
      const { summary, short_summary, background, language } = await getSummary(project.id)
      const matchedLang = languages.find((item) => item.code === language) ?? languages[0]

      const slug = await getSlug(project.name)
      loader.hide()

      const meta = await modals.open<TLandingPageMeta | undefined>(
        () => import('@/components/modal/DesModalLandingPageMeta.vue'),
        {
          id: project.id,
          author: `${user.user.first_name ?? ''} ${user.user.last_name ?? ''}`.trim(),
          authorDescription: '',
          title: project.name,
          subtitle: short_summary,
          summary,
          slug,
          language: { name: matchedLang.name, code: matchedLang.code },
        },
      )

      if (!meta) return false

      loader.show('Generating cover...')
      meta.thumbnail = encodeURIComponent(await generate3DCover(1, project.id))
      meta.authorProfilePicture = user.user?.avatar ?? (user.user?.gravatar ? user.user.gravatar + '400' : undefined)
      loader.hide()

      if (background) {
        meta.background = background
      }

      store.value = meta

      router.push('/landing-pages/create')
      return true
    } catch {
      loader.hide()
      return false
    }
  }

  async function update(id: number, html: string, main_css: string, section_css: string): Promise<boolean> {
    try {
      const res = await http.post<{ result: boolean }>(`/landing-pages`, { id, html, main_css, section_css })
      return res.result || false
    } catch {
      return false
    }
  }

  async function changeSlug(id: number, slug: string): Promise<boolean> {
    try {
      const res = await http.post<{ result: boolean }>(`/landing-pages/slug`, { id, slug })
      return res.result || false
    } catch {
      return false
    }
  }

  return {
    landingPages,
    landingPage,
    projectId,
    languages,
    fetch,
    fetchById,
    getProjectMeta,
    generate3DCover,
    generateThumbnail,
    rename,
    remove,
    removeMany,
    create,
    update,
    changeSlug,
    filteredAndSorted,
    sort,
    query,
    sortOptions,
  }
})
