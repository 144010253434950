import { defineAsyncComponent, markRaw, ref } from 'vue'
import { defineStore } from 'pinia'
import { useEventListener } from '@vueuse/core'

type TSnackbarItem = {
  id: string
  message: string | (() => Promise<any>)
  timeout: ReturnType<typeof setTimeout> | null
  type?: ISnackbarType
  noClose?: boolean
  component?: ReturnType<typeof defineAsyncComponent>
}

type TSnackbarOptions = {
  permanent?: boolean
  type?: ISnackbarType
  noClose?: boolean
  custom?: boolean
}

type ISnackbarType = 'danger' | 'info' | 'success'

export const useSnackbarStore = defineStore('snackbar', () => {
  const list = ref<TSnackbarItem[]>([])

  useEventListener(window, 'message', (e) => {
    if (e.data.type === 'snack' && e.data.message) {
      add(e.data.message)
    }
  })

  function add(message: string, options?: TSnackbarOptions & { custom?: false }): string
  function add(message: () => Promise<any>, options?: TSnackbarOptions & { custom: true }): string
  function add(message: string | (() => Promise<any>), options?: TSnackbarOptions): string {
    const id = Math.random().toString(16).slice(2)
    const timeout = options?.permanent ? null : setTimeout(() => remove(id), 6000)
    const type = options?.type
    const noClose = options?.noClose
    const component = typeof message !== 'string' ? markRaw(defineAsyncComponent({ loader: message })) : null

    const item: TSnackbarItem = { id, message, timeout, type, noClose, component }

    list.value.push(item)

    return id
  }

  function remove(id: string) {
    const item = list.value.find((e) => e.id === id)
    item?.timeout && clearTimeout(item.timeout)

    list.value = list.value.filter((e) => e.id !== item?.id)
  }

  return { add, remove, list }
})
