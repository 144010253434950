/* eslint-disable no-debugger */

class UnsplittableElementError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'UnsplittableElementError'
  }
}

class SplitListElemResult {
  element: HTMLElement
  overflow: Node[]

  constructor(element: HTMLElement, overflow: Node[]) {
    this.element = element
    this.overflow = overflow
    if (this.element.tagName === 'BR') {
      debugger
    }
  }
}

class SplitElemResult {
  first: HTMLElement
  second: HTMLElement | null

  constructor(first: HTMLElement, second: HTMLElement | null) {
    this.first = first
    this.second = second

    if (!this.first || this.first.innerHTML.trim() === '') {
      debugger
      throw new Error('Invalid argument!')
    }
    if (!this.second || this.second.innerHTML.trim() === '') {
      //this can happen when splitting text by br but there is no br
      debugger
    }
    if (this.first.tagName === 'BR') {
      debugger
    }
  }
}

export class ElementSplitter {
  textualTags: string[] = ['BR']
  wrapperToKeepClass = 'facebook-post-main-container'
  nonSplittableElements: string[] = ['IMG', 'SVG', 'VIDEO']
  nonSplittableInfo: Record<string, string[]> = {
    DIV: ['embed-video', 'new-embed-audio-wrapper', 'jumbotron'],
  }

  constructor() {}

  isEmptyTextNode(node: Node, trim: boolean = false): boolean {
    return node.nodeType === Node.TEXT_NODE && (trim ? node.nodeValue?.trim() === '' : node.nodeValue === '')
  }

  allChildNodes(element: HTMLElement, trim: boolean = false): Node[] {
    const listTag = element.tagName
    let rows: HTMLElement[] | Node[] = []

    if (['UL', 'OL'].includes(listTag)) {
      rows = Array.from(element.children) as HTMLElement[]
    } else if (listTag === 'TABLE') {
      const table = element as HTMLTableElement
      Array.from(table.tBodies).forEach((tb) => {
        rows = rows.concat(Array.from(tb.children) as HTMLElement[])
      })
    } else {
      rows = this.removeEmptyTextNodesFromList(Array.from(element.childNodes) as HTMLElement[], trim)
    }
    return rows
  }

  isNotAllowedToBeSplit(element: HTMLElement): boolean {
    const tag = element.tagName
    const blackListedClasses = this.nonSplittableInfo[tag]
    if (!blackListedClasses) {
      return false
    }
    return blackListedClasses.some((cls) => element.classList.contains(cls))
  }

  getCorrectParent(element: HTMLElement): HTMLElement {
    if (element.tagName === 'SPAN') {
      if (!element.parentElement) {
        throw new Error('Invalid argument element, must have parent!')
      }
      return element.parentElement
    }
    return element.tagName !== 'LI'
      ? element
      : (element.closest('.absolute-content-container, #contentwrap') as HTMLElement)
  }

  handleElementWithBigFirstChild(element: HTMLElement, removed: Node[], okFunc: () => boolean): Node[] {
    const firstChild = removed.shift() // we keep the first child in place, won't be pushed on next page
    if (!firstChild) {
      throw new Error('Invalid argument removed, can not be empty!')
    }

    if (element.tagName === 'TABLE') {
      ;(element as HTMLTableElement).tBodies[0].appendChild(firstChild)
    } else {
      element.appendChild(firstChild)
    }

    if (
      (firstChild instanceof HTMLElement && this.nonSplittableElements.includes(firstChild.tagName)) ||
      this.isNotAllowedToBeSplit(element)
    ) {
      console.log('FOUND unsplittable large element')
      console.log(firstChild)
      const contentContainer = this.getCorrectParent(element)
      const maxH = this.calculateInnerHeight(contentContainer)
      this.forceElementToFitSize(firstChild, maxH)
    } else {
      const parent = firstChild.parentElement
      if (!parent) {
        throw new Error('Invalid argument firstChild, must have parent!')
      }
      try {
        /* @ts-ignore */
        const parts = this.splitElement(firstChild, parent, okFunc)
        if (parts.second) {
          removed.unshift(parts.second)
        }
      } catch (e) {
        if (!(e instanceof UnsplittableElementError)) {
          throw e
        }
        const contentContainer = this.getCorrectParent(element)
        const maxH = this.calculateInnerHeight(contentContainer)
        this.forceElementToFitSize(parent, maxH)
        this.forceElementToFitSize(firstChild, maxH)
      }
    }
    return removed
  }

  forceElementToFitSize(element: Node, maxAllowedHeight: number): void {
    if (!(element instanceof HTMLElement)) {
      return
    }
    if (!element.style || element.children.length > 0) {
      return
    }
    const mh = maxAllowedHeight + 'px'
    element.style.maxHeight = mh
    Array.from(element.children).forEach((n: Element) => {
      if (n instanceof HTMLElement) {
        n.style.maxHeight = mh
      }
    })
  }

  splitElement(elem: HTMLElement, parentContainer: HTMLElement | null, okFunc?: () => boolean): SplitElemResult {
    if (okFunc === undefined || okFunc === null) {
      if (!parentContainer) {
        throw new Error('Invalid argument, parentContainer must be provided if okFunc is missing!')
      }
      okFunc = () => !this.isOverflowing(parentContainer)
    }
    this.trimElement(elem)
    return this.splitElementUntilSatisfies(elem, okFunc, parentContainer)
  }

  splitRowWithColumns(row: HTMLElement, okFunc: () => boolean): SplitElemResult {
    const columns = Array.from(row.children).filter((child) => {
      return child.className.startsWith('col-')
    }) as HTMLElement[]

    if (columns.length === 0) {
      throw new Error('Div row with no column!')
    }

    const newRow = this.createElementFrom(row)
    newRow.className = row.className

    columns.forEach((column) => {
      const { second } = this.splitColumn(column, okFunc)
      if (second) {
        newRow.appendChild(second)
      }
    })

    return new SplitElemResult(row, newRow)
  }

  splitColumn(col: HTMLElement, okFunc: () => boolean): SplitElemResult {
    return this.splitElement(col, null, okFunc)
  }

  createNodeFrom(element: Node): Node {
    return element.cloneNode()
  }

  createElementFrom(element: HTMLElement): HTMLElement {
    return element.cloneNode() as HTMLElement
  }

  splitElementUntilSatisfies(
    element: HTMLElement,
    okFunc: () => boolean,
    parentContainer: HTMLElement | null,
  ): SplitElemResult {
    const tag = element.tagName
    const maxAllowedHeight = this.calculateInnerHeight(parentContainer)

    if (!tag) {
      console.log('wrapping text node into span to be able to split')
      // Wrap in a span if it's a text node
      const span = document.createElement('span')
      element.parentNode?.insertBefore(span, element)
      span.appendChild(element)
      element = span
    }

    if (this.nonSplittableElements.indexOf(element.tagName) >= 0 || this.isNotAllowedToBeSplit(element)) {
      throw new UnsplittableElementError('Unsplittable element!')
    }

    switch (tag) {
      case 'SPAN':
        return this.fitHTMLTextInElement(element, okFunc)
      case 'TABLE':
        return this.splitTable(element, okFunc, maxAllowedHeight)
      case 'UL':
      case 'OL':
        return this.splitListGeneric(element, okFunc, maxAllowedHeight)
      case 'DIV':
        if (element.classList.contains('row')) {
          return this.splitRowWithColumns(element, okFunc)
        } else {
          return this.splitDiv(element, okFunc, maxAllowedHeight)
        }
      case 'LI':
      case 'TR':
        return this.splitListRowGeneric(element, okFunc, maxAllowedHeight)
      default:
        return this.splitPElement(element, okFunc, maxAllowedHeight)
    }
  }

  splitTd(td: HTMLElement, okFunc: () => boolean): SplitElemResult {
    const display = window.getComputedStyle(td).display.toLowerCase()
    if (display === 'table-cell') {
      td.style.display = 'block'
    }

    const { second } = this.splitElementUntilSatisfies(td, okFunc, td.parentElement as HTMLElement)
    return new SplitElemResult(td, second)
  }

  trimElement(tdElement: HTMLElement): void {
    const isWhitespaceNode = (node: Node): boolean => {
      return (
        (node.nodeType === Node.TEXT_NODE && node.textContent?.trim() === '') ||
        (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName === 'BR')
      )
    }

    while (tdElement.firstChild && isWhitespaceNode(tdElement.firstChild)) {
      tdElement.removeChild(tdElement.firstChild)
    }

    while (tdElement.lastChild && isWhitespaceNode(tdElement.lastChild)) {
      tdElement.removeChild(tdElement.lastChild)
    }

    // Trim non-breaking spaces and other whitespace characters from text nodes
    tdElement.childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        node.textContent = node.textContent?.replace(/^\s+|\s+$/g, '') ?? ''
      }
    })
  }

  splitTableRow(row: HTMLElement, okFunc: () => boolean, maxAllowedHeight: number): SplitElemResult {
    const tds = row.querySelectorAll<HTMLTableCellElement>(':scope > td')
    if (tds.length === 0) {
      throw new Error('Table row with no column!')
    }

    const newRow = this.createElementFrom(row)
    tds.forEach((td) => {
      if (okFunc()) {
        const td2 = this.createNode('td', '', '')
        newRow.appendChild(td2)
      } else {
        const backupTd = td.innerHTML
        try {
          this.trimElement(td)
          const split = this.splitTd(td, okFunc)
          if (!split.second) {
            throw new Error('splitTd failed to split column')
          }
          newRow.appendChild(split.second)
        } catch (e) {
          // This can happen if we try to split a column that is not the actual cause of the overflow
          // since it is not the cause any attempts to split will not work
          console.log('splitTableRow failed to split column, force a max height on it')
          const extraPaddings = this._calculateExtraPaddings(td)
          const height = maxAllowedHeight - extraPaddings
          td.innerHTML = backupTd
          td.style.maxHeight = height + 'px'
          const td2 = this.createNode('td', '', '')
          newRow.appendChild(td2)
        }
      }
    })

    return new SplitElemResult(row, newRow)
  }

  private _calculateExtraPaddings(element: HTMLElement): number {
    const styles = window.getComputedStyle(element)
    let extraPaddings =
      parseFloat(styles.paddingTop) +
      parseFloat(styles.paddingBottom) +
      parseFloat(styles.marginTop) +
      parseFloat(styles.marginBottom)
    if (!isFinite(extraPaddings)) {
      extraPaddings = 0
    }
    return extraPaddings
  }

  splitListRowGeneric(row: HTMLElement, okFunc: () => boolean, maxAllowedHeight: number): SplitElemResult {
    const rowTag = row.tagName
    if (rowTag === 'TR') {
      return this.splitTableRow(row, okFunc, maxAllowedHeight)
    }

    const { first, second } = this.splitPElement(row, okFunc, maxAllowedHeight)
    if (!second) {
      console.log(row.outerHTML)
      throw new Error('splitPElement failed to split row')
    }
    const newRow = second
    const newRowTag = newRow.tagName

    if (!['LI', 'UL'].includes(newRowTag)) {
      const tempRow = this.createElementFrom(row)
      tempRow.appendChild(newRow)
    }

    return new SplitElemResult(first, newRow)
  }

  splitTable(table: HTMLElement, okFunc: () => boolean, maxAllowedHeight: number): SplitElemResult {
    // Store the original display style
    const display = table.style.display
    table.style.display = 'block'

    const res = this.splitListGeneric(table, okFunc, maxAllowedHeight)

    // Restore the previous display style to be safe
    res.first.style.display = display
    if (res.second) {
      res.second.style.display = display
    }
    return res
  }

  createSiblingList(list: HTMLElement, newRows: Node[]): HTMLElement {
    newRows.forEach((r) => {
      if (r instanceof HTMLElement) {
        r.remove()
      } else if (r.parentElement) {
        r.parentElement.removeChild(r)
      }
    })

    const listTagName = list.tagName
    const className = list.getAttribute('class') ?? ''
    const newList = this.createNode(listTagName, '', className)
    let container = newList

    if (listTagName === 'OL') {
      const start = list.getAttribute('start') ? parseInt(list.getAttribute('start')!) : 1
      newList.setAttribute('start', (start + list.children.length).toString())
    } else if (listTagName === 'TABLE') {
      const th = list.querySelector('thead')
      const tb = list.querySelector('tbody')
      if (th) {
        newList.appendChild(th.cloneNode(true))
      }
      if (tb) {
        newList.appendChild(tb.cloneNode(false))
        container = newList.querySelector('tbody')!
      }
    }

    newRows.forEach((r) => {
      container.appendChild(r)
    })

    return newList
  }

  copyClassName(element: HTMLElement, className: string): HTMLElement {
    if (className && className.length > 0 && element && element) {
      element.setAttribute('class', className)
    }
    return element
  }

  splitListGeneric(list: HTMLElement, okFunc: () => boolean, maxAllowedHeight: number): SplitElemResult {
    const split = this.splitElemWithChildren(list, okFunc, maxAllowedHeight)
    const newList = this.createSiblingList(list, split.overflow)
    return new SplitElemResult(list, newList)
  }

  calculateInnerHeight(element: HTMLElement | null): number {
    if (!element) {
      return 10314 //a magic number large enough and easy to notice it is magic
    }
    const styles = window.getComputedStyle(element, null)
    return (
      parseInt(styles.height.replace('px', '')) -
      parseInt(styles.paddingTop.replace('px', '')) -
      parseInt(styles.paddingBottom.replace('px', ''))
    )
  }

  removeEmptyTextNodesFromList(nodesList: NodeList | HTMLElement[], trim: boolean): Node[] {
    const emptyNodes = Array.from(nodesList).filter((node) => this.isEmptyTextNode(node, trim))
    emptyNodes.forEach((node) => node.parentNode?.removeChild(node))
    return Array.from(nodesList)
  }

  removeChildrenThatOverflow(element: HTMLElement, okFunc: () => boolean): Node[] {
    if (okFunc()) {
      return []
    }
    const children = this.allChildNodes(element, true)
    if (!children || children.length === 0) {
      // we have a large element with one large text
      const className = element.className
      const node = this.createNode('p', element.textContent || '', className)
      element.textContent = ''
      return [node]
    }

    if (children.length > 4) {
      return this.removeChildrenThatOverflowUseBinarySearch(element, children, okFunc)
    } else {
      return this.removeChildrenThatOverflowClassic(element, children, okFunc)
    }
  }

  removeChildrenThatOverflowClassic(element: HTMLElement, children: Node[], okFunc: () => boolean): Node[] {
    const removed: Node[] = []

    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i]
      child.parentNode?.removeChild(child)
      removed.unshift(child)
      if (okFunc()) {
        if (i === 0) {
          return this.handleElementWithBigFirstChild(element, removed, okFunc)
        }
        break
      }
    }
    return removed
  }

  removeChildrenThatOverflowUseBinarySearch(element: HTMLElement, children: Node[], okFunc: () => boolean): Node[] {
    if (okFunc()) {
      return []
    }
    if (children.length < 3) {
      throw new Error('Invalid input, we need elements with many children, only ' + children.length)
    }
    let leftAndOk = 0
    let rightAndNotOk = children.length - 1
    let half = Math.floor(children.length / 2)

    // eslint-disable-next-line no-constant-condition
    while (true) {
      for (let i = half + 1; i < rightAndNotOk + 1; i++) {
        const child = children[i]
        if (child instanceof HTMLElement) {
          child.remove()
        } else {
          child.parentNode?.removeChild(child)
        }
      }
      if (okFunc()) {
        leftAndOk = half
        if (leftAndOk + 1 === rightAndNotOk) {
          break
        }
        for (let i = leftAndOk; i < rightAndNotOk; i++) {
          element.appendChild(children[i])
        }
      } else {
        if (half === 0) {
          return this.handleElementWithBigFirstChild(element, children, okFunc)
        }
        rightAndNotOk = half
      }
      const newHalf = Math.floor((leftAndOk + rightAndNotOk) / 2)
      if (newHalf === half) {
        break
      }
      half = newHalf
    }
    return children.slice(leftAndOk + 1)
  }

  splitContainerWithTextAndImage(element: HTMLElement, maxHeight: number): SplitElemResult {
    const child = element.children[0] as HTMLElement
    child.parentNode?.removeChild(child)
    const tag = element.tagName
    const className = element.className
    const node = this.createNode(tag, '', className)
    node.appendChild(child)

    const extraPaddings = this._calculateExtraPaddings(element)
    const height = maxHeight - extraPaddings

    if (child.style.height && parseInt(child.style.height) > maxHeight) {
      child.style.height = `${height}px`
    }

    child.style.maxHeight = `${height}px`

    return new SplitElemResult(element, node)
  }

  splitP_With1Img(element: HTMLElement, okFunc: Function, maxAllowedHeight: number): SplitElemResult {
    if (!element.textContent?.trim()) {
      this.forceElementToFitSize(element, maxAllowedHeight)
      throw new UnsplittableElementError('Unsplittable splitP_With1Img')
    }
    return this.splitContainerWithTextAndImage(element, maxAllowedHeight)
  }

  _wrapOverflowIfNeeded(overflow: (Node | HTMLElement)[], element: HTMLElement): HTMLElement | null {
    if (!overflow || overflow.length <= 0) {
      return null
    }

    const firstOverflow = overflow[0] as HTMLElement

    if (overflow.length > 1) {
      const cont = this.createElementFrom(element)
      for (let i = 0; i < overflow.length; i++) {
        cont.appendChild(overflow[i] as Node)
      }
      return cont
    } else {
      if (firstOverflow.tagName !== element.tagName && !firstOverflow.classList.contains(this.wrapperToKeepClass)) {
        const container = this.createElementFrom(element)
        container.appendChild(firstOverflow)
        return container
      }
      return firstOverflow
    }
  }

  mergeWithNextElement(elem: Element): void {
    const nextElem = elem.nextElementSibling
    if (!nextElem) {
      console.log('mergeElementWithNext no sibling found')
      return
    }

    const tag = elem.tagName
    const tagNext = nextElem.tagName

    if (tag !== tagNext) {
      alert(
        'We cannot merge a ' + tag + ' element with a ' + tagNext + ' element. Only similar elements can be merged!',
      )
      return
    }

    if (tag === 'TABLE') {
      const tHeads = nextElem.querySelectorAll('thead')
      tHeads.forEach((thead) => thead.remove())
    }

    const children = Array.from(nextElem.childNodes)
    children.forEach((child) => elem.appendChild(child))

    nextElem.remove()
  }

  isTextNodeInsideSimpleSpan(node: Node): boolean {
    const parent = node.parentElement
    if (!parent) {
      return false
    }
    return parent.childNodes.length === 1
  }

  splitPElement(element: HTMLElement, okFunc: () => boolean, maxAllowedHeight: number): SplitElemResult {
    this.trimElement(element)
    const childElemCount = element.children.length
    const childNodesCount = element.childNodes.length
    const layoutTags = ['TABLE', 'DIV', 'LI', 'UL', 'OL']
    const unsplittableTags = ['BR']

    // simple case no child elements
    if (childElemCount === 0) {
      if (element.innerHTML.trim() === '') {
        throw new UnsplittableElementError('Unsplittable element!')
      }
      return this.fitHTMLTextInElement(element, okFunc)
    }

    // special case with P with 1 img
    if (childElemCount === 1 && element.children[0].tagName === 'IMG') {
      return this.splitP_With1Img(element, okFunc, maxAllowedHeight)
    }

    if (childElemCount === 1 && element.children[0].tagName === 'PRE') {
      const res2 = this.splitElemWithChildren(element, okFunc, maxAllowedHeight)
      const second = this._wrapOverflowIfNeeded(res2.overflow, element)
      return new SplitElemResult(element, second)
    }

    // the P has 1 unsplittable node
    if (childElemCount === 1 && childNodesCount === 1 && unsplittableTags.includes(element.children[0].tagName)) {
      throw new UnsplittableElementError(
        `Can't split element, the child is big and unsplittable ${element.children[0].tagName}`,
      )
    }

    // the P has 1 textual child elem and no other text nodes
    if (childElemCount === 1 && childNodesCount === 1 && !layoutTags.includes(element.children[0].tagName)) {
      return this.splitPElementWithOneChild(element, okFunc)
    }

    if (element.tagName === 'P' && this.containsOnlyTextAndTextStuff(element)) {
      return this.fitHTMLTextInElement(element, okFunc)
    }
    if (childElemCount === 1 && element.childElementCount === 1 && ['UL'].includes(element.children[0].tagName)) {
      return this.splitListGeneric(element.children[0] as HTMLElement, okFunc, maxAllowedHeight)
    }
    const res = this.splitElemWithChildren(element, okFunc, maxAllowedHeight)
    // eslint-disable-next-line prefer-const
    let { element: first, overflow } = res

    if (!okFunc()) {
      try {
        const split = this.splitPElement(first, okFunc, maxAllowedHeight)
        if (!split.second) {
          throw new Error('splitPElement failed to split')
        }
        overflow.unshift(split.second)
        first = split.first
      } catch (e) {
        overflow.forEach((node) => {
          element.append(node)
        })
        throw e
      }
    }

    const second = this._wrapOverflowIfNeeded(overflow, element)
    return new SplitElemResult(first, second)
  }

  fitHTMLTextInElementWithSeparator(
    text: string,
    element: Node,
    okFunc: () => boolean,
    separator: string,
  ): SplitElemResult {
    if (okFunc()) {
      console.log('FITTING HTML TEXT,, WEIRD everything fit JUST AFTER we started, maybe the separator caused it>')
      throw new Error('Invalid argument, element should be overflowing!')
    }

    const totalLength = this.splitText(text, separator, null).length
    if (totalLength === 1) {
      return this._attemptWithNextHTMLSeparator(text, element, okFunc, separator)
    }

    for (let i = totalLength - 1; i > 0; i--) {
      this.setHTMLTextInElement(element, text)
      const { first, second } = this.splitElemWithHTMLTextInParts(element, separator, i)
      const isOK = okFunc()

      if (!isOK) {
        if (element.parentElement && !this.isOverflowing(element.parentElement)) {
          // this should not happen, unless the ok function uses the wrong parent container or other weird stuff
          debugger
        }
        if (i === 1) {
          return this._attemptWithNextHTMLSeparator(text, element, okFunc, separator)
        }
      } else {
        // not overflowing so we return
        return new SplitElemResult(first, second)
      }
    }

    // this should not happen the i === 0 check should catch this
    debugger
    throw new Error('Invalid argument, element should be overflowing!')
  }

  getNextHTMLTextSeparator(separator: string): string | null {
    switch (separator) {
      case '': {
        return null
      }
      case ' ': {
        return ''
      }
      case '<br>': {
        return ' '
      }
      case '<br><br>': {
        return '.'
      }
      case '.': {
        return '<br>'
      }
      default: {
        throw new Error(`Unknown text separator ${separator}`)
      }
    }
  }

  _attemptWithNextHTMLSeparator(
    text: string,
    element: Node,
    okFunc: () => boolean,
    separator: string,
  ): SplitElemResult {
    const nextSeparator = this.getNextHTMLTextSeparator(separator)
    if (nextSeparator === null) {
      const maxHeight = element?.parentElement?.style?.maxHeight
      this.setHTMLTextInElement(element, text)
      //we already tried this, nothing we can do then throw an error to prevent infinite loop
      throw new UnsplittableElementError(
        `Error fitting html text in element, text was: \n ${text} , \n parent max height was: ${maxHeight}`,
      )
    }
    if (!(element instanceof HTMLElement)) {
      throw new Error('Not implemented support for Node, element must be an HTMLElement!')
    }
    return this.fitHTMLTextInElementWithSeparator(text, element, okFunc, nextSeparator)
  }

  splitText(text: string, separator: string | undefined = undefined, halfFirstWord: number | null = null): string[] {
    if (separator === undefined || separator === null) {
      separator = '.'
    }

    const sep = separator !== '' ? separator : ' '
    const totalParts = text.split(sep).filter((str) => str.trim().length > 0)

    if (separator === '') {
      const first = totalParts.shift()
      const half = halfFirstWord !== null ? halfFirstWord : Math.ceil(first!.length / 2)
      const firstWord = first!.slice(0, half)
      const secondWord = first!.slice(half)
      totalParts.unshift(secondWord)
      totalParts.unshift(firstWord)
    }

    return totalParts
  }

  splitElemWithOneChildOrText(elem: HTMLElement, separator: string, keepNumber: number): SplitElemResult {
    if (!this.hasElemOneOrLessChildNodes(elem)) {
      throw new Error(
        'Invalid args, splitElemWithOneChildOrText needs an elem with max 1 child, validated with hasElemOneOrLessChildNodes',
      )
    }
    if (keepNumber <= 0) {
      throw new Error('Invalid args, splitElemWithOneChildOrText keepNumber must be strict positive!')
    }

    if (elem.children.length === 0) {
      return this.splitElemWithHTMLTextInParts(elem, separator, keepNumber)
    }
    const span = elem.children[0] as HTMLElement
    elem.removeChild(span)
    elem.innerHTML = ''
    const { first, second } = this.splitElemWithHTMLTextInParts(span, separator, keepNumber)
    if (!second) {
      throw new Error('splitElemWithHTMLTextInParts failed to split')
    }
    const clone = this.createElementFrom(elem)
    elem.appendChild(first)
    clone.appendChild(second)
    return new SplitElemResult(elem, clone)
  }

  setTextInElement(element: HTMLElement, text: string): void {
    //@ts-ignore //TODO update ts config to a recent target
    text = text.replaceAll('\n', '').trim()

    if (element.children.length === 1) {
      const child = element.children[0]
      if (!(child instanceof HTMLElement)) {
        throw new Error('Invalid argument, elem must have a child of type HTMLElement')
      }
      child.textContent = '' // Ensure the content is reset
      child.textContent = text // Set the text properly
    } else if (element.children.length === 0) {
      element.textContent = '' // Clear existing content first
      element.textContent = text
    } else {
      throw new Error('Invalid inputs, elem must have at most 1 child')
    }
  }

  setHTMLTextInElement(element: Node, text: string): void {
    if (element instanceof HTMLElement) {
      if (element.children.length === 1 && !this.textualTags.includes(element.children[0].tagName)) {
        element.children[0].innerHTML = text
      } else if (element.children.length === 0) {
        element.innerHTML = text
      } else if (this.containsOnlyTextAndTextStuff(element)) {
        element.innerHTML = text
      } else {
        console.log(element.outerHTML)
        console.log(text)
        throw new Error('Invalid inputs, elem must have at most 1 child')
      }
    } else {
      element.textContent = text
    }
  }

  containsOnlyTextAndTextStuff(element: HTMLElement): boolean {
    const nodes: Node[] = Array.from(element.childNodes)
    const nonTextual = nodes.find((n) => {
      if (n.nodeType === Node.TEXT_NODE) return false
      if (n.nodeType === Node.ELEMENT_NODE && n instanceof HTMLElement) {
        return !this.textualTags.includes(n.tagName)
      }
      return true
    })

    return nonTextual === undefined
  }

  public hasElemOneOrLessChildNodes(elem: HTMLElement): boolean {
    if (!elem) {
      debugger
    }
    return elem?.childNodes?.length <= 1
  }

  splitPElementWithOneChild(element: HTMLElement, okFunc: () => boolean): SplitElemResult {
    const child = element.children[0] as HTMLElement
    const { second } = this.fitHTMLTextInElement(child, okFunc)
    if (!second) {
      throw new Error('splitPElementWithOneChild failed to  split')
    }
    const sec = this.createElementFrom(element)
    sec.innerHTML = second.outerHTML
    return new SplitElemResult(element, sec)
  }

  fitHTMLTextInElement(element: HTMLElement, okFunc: () => boolean): SplitElemResult {
    if (okFunc()) {
      console.log('FITTING HTML TEXT,, WEIRD everything fit BEFORE we started,set max Height caused it??>, ')
      throw new Error('Invalid argument, element should be overflowing!')
    } else {
      return this.fitHTMLTextInElementWithSeparator(element.innerHTML, element, okFunc, '<br><br>')
    }
  }

  splitDiv(div: HTMLElement, okFunc: () => boolean, maxAllowedHeight: number): SplitElemResult {
    if (div.classList.contains('toc-wrapper')) {
      return this.splitTOC(div, okFunc, maxAllowedHeight)
    } else {
      return this.splitPElement(div, okFunc, maxAllowedHeight)
    }
  }

  isOverflowing(element: HTMLElement): boolean {
    if (!element) {
      throw new Error('Invalid argument!')
    }

    const display = element.style.display.toLowerCase()
    if (display && ['inline', 'table-cell', 'table'].indexOf(display) >= 0) {
      element.style.display = 'block'
    }
    return element.offsetHeight < element.scrollHeight
  }

  allTextNodesSplittableWith(element: HTMLElement, text: string): Text[] {
    return Array.from(element.childNodes).filter((node): node is Text => {
      return !!(
        node.nodeType === Node.TEXT_NODE &&
        node.textContent?.trim() &&
        this._textNodeSplittableWith(node, text)
      )
    })
  }

  _textNodeSplittableWith(node: Node, sep: string): boolean {
    const content = node.textContent
    if (!content) {
      throw new Error('Invalid argument, node must have content!')
    }
    const index = content?.indexOf(sep) ?? -1
    if (index < 0) {
      return false
    }
    return index !== content.length - sep.length
  }

  /**
   * @param {string} text
   * @param {HTMLElement} element
   * @param {Function} okFunc
   * @param {string} separator
   * @param {number} halfFirstWord
   * @return {SplitElemResult}
   **/
  fitTextInElementWithSeparator(
    text: string,
    element: HTMLElement,
    okFunc: () => boolean,
    separator: string,
  ): SplitElemResult {
    if (okFunc()) {
      console.log('FITTING TEXT,, WEIRD everything fit JUST AFTER we started, maybe the separator caused it>, ')
      const debugHtml2 = element.innerHTML
      const debugHtml1 = element.outerHTML
      console.log(debugHtml1)
      console.log(debugHtml2)
      console.log('==========')
      debugger
      throw new Error('Invalid argument, element should be overflowing!')
    }

    const totalLength = this.splitText(text, separator, null).length
    if (totalLength === 1) {
      return this._attemptWithNextSeparator(text, element, okFunc, separator)
    }

    for (let i = totalLength - 1; i > 0; i--) {
      this.setTextInElement(element, text)
      const { first, second } = this.splitElemWithOneChildOrText(element, separator, i)
      const isOK = okFunc()
      if (!isOK) {
        if (element.parentElement && !this.isOverflowing(element.parentElement)) {
          debugger
        }
        if (i === 1) {
          return this._attemptWithNextSeparator(text, element, okFunc, separator)
        }
      } else {
        return new SplitElemResult(first, second)
      }
    }

    throw new Error('This code should not have been reached')
  }

  _attemptWithNextSeparator(
    text: string,
    element: HTMLElement,
    okFunc: () => boolean,
    separator: string,
  ): SplitElemResult {
    const nextSeparator = this.getNextTextSeparator(separator)
    if (nextSeparator === null) {
      // Already tried this, nothing we can do, throw an error to prevent infinite loop
      throw new UnsplittableElementError(
        `Error fitting text in element, text was \n${text} parent max height was ${element.parentElement?.style.maxHeight}`,
      )
    }
    return this.fitTextInElementWithSeparator(text, element, okFunc, nextSeparator)
  }

  getNextTextSeparator(separator: string): string | null {
    switch (separator) {
      case '': {
        return null
      }
      case ' ': {
        return ''
      }
      case '.': {
        return ' '
      }
      default: {
        throw new Error(`Unknown text separator ${separator}`)
      }
    }
  }

  splitTextNodeAt(node: Text, sep: string): void {
    let newNode: Text = node
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const offset = newNode.textContent?.indexOf(sep) ?? -1
      if (offset < 0) {
        if (newNode.textContent === '') {
          newNode.remove()
        }
        break
      }
      newNode = newNode.splitText(offset + sep.length)
    }
  }

  splitElementTextNodesAt(element: HTMLElement, separators: string[]): void {
    separators.forEach((sep) => {
      const nodes = this.allTextNodesSplittableWith(element, sep)
      nodes.forEach((node) => {
        this.splitTextNodeAt(node, sep)
      })
    })
  }

  splitElemWithChildren(element: HTMLElement, okFunc: () => boolean, maxAllowedHeight: number): SplitListElemResult {
    if (element.classList?.contains(this.wrapperToKeepClass)) {
      return this.splitSpecialWrappedStuff(element, okFunc)
    }

    this.splitElementTextNodesAt(element, ['.'])
    const removed = this.removeChildrenThatOverflow(element, okFunc)

    let children = this.allChildNodes(element, true)
    if (children.length === 1) {
      const firstChild = children[0]
      if (firstChild.textContent?.trim() === '') {
        if (firstChild instanceof HTMLElement && firstChild.innerHTML.trim() !== '') {
          debugger
        }
        children = []
      }
    }

    if (children.length > 0) {
      return new SplitListElemResult(element, removed)
    } else {
      return this.handleSplitWithChildren1stTooBig(element, removed, okFunc, maxAllowedHeight)
    }
  }

  splitSpecialWrappedStuff(element: HTMLElement, okFunc: () => boolean): SplitListElemResult {
    let removed = this.removeChildrenThatOverflow(element, okFunc)
    const children = this.allChildNodes(element, true)
    if (children.length > 0) {
      removed = [this.wrapNodesIntoCommonContainer(removed, this.wrapperToKeepClass)]
      return new SplitListElemResult(element, removed)
    }
    return new SplitListElemResult(element, removed)
  }

  wrapNodesIntoCommonContainer(nodes: Node[], wrapperClass: string): HTMLElement {
    const container = document.createElement('div')
    container.className = wrapperClass
    nodes.forEach((node) => {
      container.appendChild(node)
    })
    return container
  }

  handleSplitWithChildren1stTooBig(
    element: HTMLElement,
    removed: Node[],
    okFunc: () => boolean,
    maxAllowedHeight: number,
  ): SplitListElemResult {
    const tag = element.tagName
    const className = element.className
    const firstChild = removed.shift()
    if (!firstChild) {
      throw new Error("Invalid argument removed, can't be empty!")
    }
    if (firstChild?.nodeType === Node.TEXT_NODE && firstChild instanceof Text) {
      const textNode = firstChild as Text // Now explicitly handling firstChild as Text
      const text = textNode.wholeText
      if (!text.trim()) {
        debugger
        console.log(element)
        element.replaceChildren()
        removed.forEach((el) => element.append(el))
        throw new UnsplittableElementError('First child did not fit but has no text!')
      }
      const first = this.createNode(tag, text, className)
      element.insertAdjacentElement('afterend', first)
      try {
        return this._buildSplitListResult(first, removed, okFunc, maxAllowedHeight)
      } catch (e) {
        if (!(e instanceof UnsplittableElementError)) {
          throw e
        }
        first.remove()
        element.replaceChildren()
        removed.unshift(firstChild)
        removed.forEach((el) => element.append(el))
        throw e
      }
    } else if (firstChild instanceof HTMLElement && ['P', 'DIV', 'UL'].includes(firstChild.tagName)) {
      element.insertAdjacentElement('afterend', firstChild)
      return this._buildSplitListResult(firstChild, removed, okFunc, maxAllowedHeight)
    } else {
      const first = firstChild
      const br = this.createNode('br', '', '')
      return new SplitListElemResult(br, [first].concat(removed))
    }
  }

  _buildSplitListResult(
    first: HTMLElement,
    removed: Node[],
    okFunc: () => boolean,
    maxAllowedHeight: number,
  ): SplitListElemResult {
    const split = this.splitPElement(first, okFunc, maxAllowedHeight)
    if (!split.second) {
      //throw new Error('First child did not fit!')
    } else {
      split.second.remove()
      removed.unshift(split.second)
    }

    return new SplitListElemResult(split.first, removed)
  }

  splitTOC(div: HTMLElement, okFunc: () => boolean, maxAllowedHeight: number): SplitElemResult {
    const okFuncToc = () => {
      return !this.isOverflowing(div) && okFunc()
    }
    const split = this.splitElemWithChildren(div, okFuncToc, maxAllowedHeight)

    const div2 = this.createTOCEmptyContainer(div)
    split.overflow.forEach((e: Node) => {
      div2.appendChild(e)
    })
    return new SplitElemResult(div, div2)
  }

  createTOCEmptyContainer(div: HTMLElement): HTMLElement {
    const tocId = div.getAttribute('tocid')
    const className = div.getAttribute('class') ?? ''
    const div2 = this.createNode('div', '', className)

    if (tocId) div2.setAttribute('tocid', tocId)
    if (div.getAttribute('style')) div2.setAttribute('style', div.getAttribute('style') ?? '')

    return div2
  }

  createNode(tag: string, html: string, className: string): HTMLElement {
    return ElementSplitter.createNode(tag, html, className)
  }

  static createNode(tag: string, html: string = '', className: string = ''): HTMLElement {
    const node = document.createElement(tag)
    node.innerHTML = html
    if (className && className.length > 0) {
      node.setAttribute('class', className)
    }
    return node
  }

  splitParentAtChild(parent: HTMLElement, child: Element): HTMLElement[] {
    const children = Array.from(child.parentElement?.children || [])
    const childIndex = children.indexOf(child)

    if (childIndex === -1) return [parent]

    const splitChildren = children.slice(childIndex)
    const newList = this.createSiblingList(parent, splitChildren)

    parent.after(newList)
    return [parent, newList]
  }

  splitElemWithHTMLTextInParts(span: Node, separator: string, keepNumber: number): SplitElemResult {
    const text = span instanceof HTMLElement ? span.innerHTML.trim() : (span.textContent?.trim() ?? '')
    let parts: string[], fits: string, overflow: string

    if (separator === '') {
      parts = this.splitText(text, separator, keepNumber)
      fits = parts[0]
      overflow = parts[1]
    } else {
      parts = this.splitText(text, separator)
      fits = parts.slice(0, keepNumber).join(separator)
      overflow = parts.slice(-1 * (parts.length - keepNumber)).join(separator)
    }

    if (separator === '.') {
      fits += separator
      if (text[text.length - 1] === separator) {
        overflow += separator
      }
    }
    if (span instanceof HTMLElement) {
      span.innerHTML = fits.trim()
    } else {
      span.textContent = fits.trim()
    }

    let second: HTMLElement | null
    if (overflow) {
      if (span instanceof HTMLElement) {
        second = this.createElementFrom(span)
        second.innerHTML = overflow.trim()
      } else {
        second = this.createNode('span', overflow.trim(), '')
      }
    } else {
      second = null
    }
    if (!(span instanceof HTMLElement)) {
      const s = this.createNode('span', span.textContent?.trim() ?? '', '')
      return new SplitElemResult(s, second)
    }
    return new SplitElemResult(span, second)
  }
}
